import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import icon from '../Static/img/quotes.png';

const testimonials = [
    {
        id: 1,
        name: 'Parth Chavan (Software tester, _VOIS)',
        text: 'Suresh is  determined , focused and have that urge to learn new things everyday professionally. I was lucky enough to have him as my instructor in Learning testing. The way he managed the whole syllabus and took initiative in every task was commendable. He knows  how to tap into a persons strengths. He helps me in focusing  on the steps I need to grow professionally.',
    },
    {
        id: 2,
        name: 'Kiran Vijapure (Student, KLECET)',
        text: 'I had the pleasure of working with Suresh on our final year project, and I am impressed by their professionalism and commitment. Suresh played a crucial role in the projects success by offering innovative ideas, troubleshooting issues, and ensuring that deadlines were met. Their positive attitude and collaborative approach made the entire project experience enjoyable and rewarding.',
    },
    {
        id: 3,
        name: 'Manish Mewara (Founder, Digihand co.)',
        text: 'Choosing Suresh for our software development project was one of the best decisions we made. Their teams ability to grasp complex requirements and deliver a robust, scalable solution within our timeline was impressive. The post-launch support and quick response to our queries further solidified our trust in Suresh. We highly recommend their services to anyone in need of reliable software development.',
    },
];

const HomeTestimonials = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className='mt-20 bg-gray-100 py-10 md:p-10 rounded-2xl mb-5'>
            <p className="font-[Poppins] font-bold text-4xl text-center">Testimonials</p>
            <Slider {...settings}>
                {testimonials.map(testimonial => (
                    <div key={testimonial.id} className="p-4">
                        <div className="p-6 rounded-3xl shadow bg-violet-400 bg-opacity-70">
                            <img src={icon} alt='...' width={40} />
                            <p className="mt-2 font-[Poppins]">{testimonial.text}</p>
                            <p className="text-gray-800 mt-4 font-[Poppins]">{`- ${testimonial.name}`}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default HomeTestimonials;