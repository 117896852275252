import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Body from './Components/Body';
import Blogs from './Components/Blogs';
import Contactus from './Components/Contactus';
import Courses from './Components/Courses';

function App() {
  return (
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contactus" element={<Contactus />} />
        </Routes>
        <Footer />
      </BrowserRouter>
  );
}

export default App;