import React from 'react'
import { Link } from 'react-router-dom';
import manWithMobile from '../Static/img/devloperClientConvo.png';

const HomeCrousal = () => {
  return (
    <div className='h-screen flex flex-col items-center gap-6 mt-20 md:flex-row md:my-auto md:justify-between'>
      {/* Left Portion */}
      <div className='items-center flex flex-col text-center gap-5 md:text-left md:w-1/3 md:ml-10 md:items-start'>
        <p className='font-[Archivo Black] font-bold text-4xl md:text-5xl'>Kickstart your</p>
        <p className='font-[Poetsen One] font-bold text-4xl text-violet-700 md:text-5xl'>Bussiness Online</p>
        <span className='font-[Poppins] text-slate-500 text-md md:text-lg'><span className='font-bold text-slate-900'>Top-notch websites</span> and software with interactive designs 
        and with live support of working professional and guidence.</span>
        <Link to="/contactus"><button className='border bottom-2 hover:scale-110 transition duration-500 shadow-xl shadow-indigo-500/50 bg-violet-600 
        text-white rounded-full py-2 px-6 font-bold font-[poppins] md:py-3 md:px-10 md:text-xl md:ml-2'>Contact us</button></Link>
      </div>
      {/* Right Portion */}
      <div className='flex justify-center mt-10 md:mt-0'>
        <img src={manWithMobile} alt='...' className='w-11/12 animate-slow-bounce' />
      </div>
    </div>
  )
}

export default HomeCrousal;