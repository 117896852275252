import React from "react";

export default function ProjectCount() {
    return (
        <div className="flex justify-center">
            <div className="bg-gradient-to-r from-blue-400 to-violet-600 text-center py-10 px-10 md:py-14 md:px-16 md:rounded-2xl md:w-2/3">
                <p className="text-white font-[Poppins] text-3xl mb-5 font-bold">Our journey so far</p>
                <div className="flex flex-row items-center space-x-5 md:space-x-10 justify-center">
                    <p className="flex flex-col text-white items-center">
                        <span className="flex items-center space-x-2 text-xl md:text-3xl">4.7<lord-icon src="https://cdn.lordicon.com/divbgztv.json" trigger="hover">
                        </lord-icon>
                        </span>
                        <span className="text-xl font-[Poetsen One] md:text-2xl">Customer Ratings.</span>
                    </p>
                    <div class="inline-block h-[50px] w-0.5 bg-indigo-800 dark:bg-white/10"></div>
                    <p className="flex flex-col items-center text-white">
                        <span className="text-xl font-[Poetsen One] md:text-3xl">100+</span>
                        <span className="text-xl font-[Poetsen One] md:text-2xl">Project Completed.</span>
                    </p>
                    <div class="inline-block h-[50px] w-0.5 bg-indigo-800 dark:bg-white/10"></div>
                    <p className="flex flex-col items-center text-white">
                        <span className="text-xl font-[Poetsen One] md:text-3xl">50+</span>
                        <span className="text-xl font-[Poetsen One] md:text-2xl">Happy Clients.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}