import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Static/img/logo.png';

const Footer = () => {
  return (
    <div className='flex flex-col bg-slate-100 px-10 py-16 md:mt-10 md:flex-row md:grid md:grid-cols-3'>
      <div className='flex flex-col gap-3'>
        <Link to="/">
          <div className='flex items-center text-3xl font-bold'>
            <img className='mt-1 w-[2rem] md:w-[3rem] md:mt-2' src={logo} alt='...' />
            <p className='text-sm md:text-3xl sm:ml-1'>Codexpohub</p>
          </div>
        </Link>
        <p className='text-slate-700 text-lg'>
          Codexpohub was started with a mission to provide affordable & high quality software / webistes services for clients and education for everyone. we wish to bring all our courses with top-notch content to our students at pocket friendly prices with lifetime access.
        </p>
        <span className='font-[Poppins]'>©2024 Codexpohub. All rights reserved</span>
      </div>
      <div className='flex flex-col md:mx-auto gap-2 text-lg mt-5 font-[Poppins]'>
        <Link to="/blogs"><span>Blogs</span></Link>
        <Link to="/courses"><span>Courses</span></Link>
        <Link to="/contactus"><span>Contact us</span></Link>
      </div>
      <div className='flex flex-col md:mx-auto gap-1 mt-5'>
        <p className='text-lg font-[Poppins]'>Get in Touch</p>
        <div className='space-x-3'>
          <span className='text-xl md:text-2xl cursor-pointer'>
            <Link to='https://www.instagram.com/codexpohub?igsh=MmRmdXU5Z2l1Y3Bs' target='_blank'>
              <ion-icon name="logo-instagram"></ion-icon>
            </Link>
          </span>
          <span className='text-xl md:text-2xl cursor-pointer'>
            <Link to='https://github.com/sureshvakude' target='_blank'>
              <ion-icon name="logo-github"></ion-icon>
            </Link>
          </span>
          <span className='text-xl md:text-2xl cursor-pointer'>
            <Link to="https://leetcode.com/sureshvakude/" target='_blank'>
              <ion-icon name="code-slash-outline"></ion-icon>
            </Link>
          </span>
        </div>
        <span className='text-lg font-[Poppins] font-bold mt-5'>Contact us</span>
        <span className='text-md font-[Poppins]'>+91 9880083830</span>
        <span className='text-md font-[Poppins]'>codexpohub@gmail.com</span>
      </div>
    </div>
  );
}

export default Footer;