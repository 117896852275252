import React from 'react'
import HomeCrousal from './HomeCrousal';
import HomeServices from './HomeServices';
import HomeAboutus from './HomeAboutus';
import HomeTestimonials from './HomeTestimonials';
import ProjectCount from './ProjectCount';

const Body = () => {
  return (
    <div className='container mx-auto overflow-hidden'>
    <HomeCrousal/>
    <ProjectCount/>
    <HomeServices/>
    <HomeAboutus/>
    <HomeTestimonials/>
    </div>
  )
}

export default Body;