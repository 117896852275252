import React from 'react';
import founder from '../Static/img/founder.jpg'
import { Link } from 'react-router-dom';

const Contactus = () => {
  return (
    <div className='w-[85%] mx-auto mt-5 mb-5 md:mt-24 md:text-center'>
      <p className='font-serif text-2xl md:text-3xl md:mb-4'>Contact Us</p>
      <span className='font-serif text-slate-600'>
        If you have any suggestions, whether they're related to work or study, you can message us. We will respond to you as early as possible.
      </span>

      <div className='flex flex-col items-center md:mt-5 md:h-96'>
        <div className='mt-5 flex flex-col items-center gap-5 border-2 rounded-lg md:w-1/2 p-5 bg-slate-100'>
          <p className='text-3xl font-[poppins] font-semibold'>Feel free to contact me !</p>
          <Link to="https://sureshvakude.github.io/suresh-vakude/"><img className='w-28 rounded-full' src={founder} alt='...' /></Link>
          <div className='flex items-center space-x-5'>
            <Link to="https://www.instagram.com/ig__suresh?igsh=MW4wYzJlaWtlYnQ0cQ=="><img className='w-12' src='https://seeklogo.com/images/I/instagram-new-2016-logo-4773FE3F99-seeklogo.com.png' alt='...' target="_blank" /></Link>
            <Link to="https://wa.me/9880083830"><img className='w-14' src='https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png' alt='...' target="_blank" /></Link>
            <Link to="mailto:codexpohub@gmail.com"><img className='w-14' src='https://static.vecteezy.com/system/resources/previews/016/716/465/non_2x/gmail-icon-free-png.png' alt='...' target="_blank" /></Link>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Contactus;
