import React from 'react';
import about from '../Static/img/develoer.png';

const HomeAboutus = () => {
  return (
    <div className='text-center mt-20'>
      <p className='font-[Poppins] font-bold text-4xl'>About us</p>
      <div className='flex flex-col md:flex-row items-center md:items-start mt-5 md:justify-center'>
        <img className='my-2 w-9/12 md:w-6/12 lg:w-4/12' src={about} alt='...' />
        <div className='w-11/12 md:w-8/12 lg:w-6/12 md:ml-5'>
          <p className='text-start text-slate-600 font-[Poppins] mx-3 md:mx-0 md:mt-5'>
            Hello there! I'm Suresh, an engineer with a passion for turning ideas into reality. After completing my Bachelor's in Technology (B.Tech), I ventured into the dynamic world of software development and currently work as a software developer at Actysystem India.
            <br />
            Driven by a desire to contribute more, I've embarked on an exciting journey to empower individuals and businesses through codexpohub. At codexpohub, we specialize in creating tailored solutions that span the digital spectrum.
            <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            At codexpohub, we don't just build products; we forge partnerships. Our commitment is to deliver excellence and turn your vision into reality. Join us on this exciting journey, and let's shape the future together.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutus;