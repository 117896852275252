import React from 'react';
import website from '../Static/img/websitedev.png';
import software from '../Static/img/softwaredev.png';
import android from '../Static/img/androiddev.png';
import teaching from '../Static/img/teachdev.png';

const services = [
  {
    img: website,
    title: "Website Development",
    description: "Static and Dynamic multi-functional website. Whether you prefer information-based static website or a dynamic website with interactive features and databases, we have the expertise to create a website that suits your vision and business needs.",
  },
  {
    img: software,
    title: "Software Development",
    description: "We create software that helps your business work better, faster, and smarter. Our team designs and builds customized applications that make your tasks easier, your efficiency, and encourage new ideas. We are dedicated to providing top-notch software.",
  },
  {
    img: android,
    title: "Android Development",
    description: "We offer Android app development services that can help you reach and engage with your mobile audience effectively. Our skilled developers create dynamic and user-friendly apps, ensuring your business stays connected with your mobile customers.",
  },
  {
    img: teaching,
    title: "Teaching / Instructor",
    description: "Explore a world of knowledge with our extensive range of courses. From all programming languages to essential skills like DSA and full-stack, our expertly crafted courses empower students to master the skills needed for success in the digital age.",
  },
];

const ServiceCard = ({ img, title, description }) => (
  <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
    <img className="w-full h-52 object-cover bg-indigo-400" src={img} alt={title} />
    <div className="p-5 text-start">
      <h5 className="text-xl font-bold mb-2 text-gray-900">{title}</h5>
      <p className="text-gray-700">{description}</p>
    </div>
  </div>
);

const HomeServices = () => (
  <div className="mt-20 text-center">
    <p className="font-[Poppins] font-bold text-4xl">Explore Services</p>
    <div className="flex flex-col gap-5 items-center mt-10 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10">
      {services.map((service, index) => (
        <ServiceCard key={index} {...service} />
      ))}
    </div>
  </div>
);

export default HomeServices;
