import React from 'react';
import working from '../Static/videos/notfound.webm';

const Blogs = () => {
    return (
        <div className='text-center overflow-hidden'>
            <p className='font-serif text-4xl mt-5 md:mt-24'>blogs</p>
            <video controls={false} autoPlay loop muted className='mx-auto w-11/12 mt-5 md:w-1/3'>
                <source src={working} type="video/webm" />
                Sorry, your browser doesn't support embedded videos.
            </video>
            <p className='text-2xl font-serif'>Sorry, The blogs not posted yet...!</p>
        </div>
    );
};

export default Blogs;