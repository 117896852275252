import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Static/img/logo.png'
import LoadingBar from 'react-top-loading-bar';
import founderImage from '../Static/img/founder.jpg';

const Navbar = () => {
  const [loadingBarProgress, setLoadingBarProgress] = React.useState(0);
  const location = useLocation();

  const onToggleMenu = (e) => {
    const navLinks = document.querySelector('.nav-links');
    e.target.name = e.target.name === 'menu' ? 'close' : 'menu';
    navLinks.classList.toggle('top-[0%]');
  };

  useEffect(() => {
    setLoadingBarProgress(0);
    setTimeout(() => {
      setLoadingBarProgress(100);
    }, 100);
    return () => {
      setLoadingBarProgress(0);
    };
  }, [location.pathname]);

  return (
    <>
      <LoadingBar color="#6366f1" progress={loadingBarProgress} onLoaderFinished={() => setLoadingBarProgress(0)} />
      <header className='font-[Poppins] bg-white py-3 border-b-2 z-50 md:fixed md:w-full md:top-0 md:left-0'>
        <nav className='flex justify-between items-center w-[85%] mx-auto'>
          <Link to="/"><div className='flex items-center text-3xl font-bold'>
            <img className=' mt-1 w-[2rem] md:w-[3rem] md:mt-2' src={logo} alt='...' />
            <p className='text-sm md:text-xl sm:ml-1'>Codexpohub</p>
          </div></Link>
          <div className='nav-links flex items-center px-5 duration-500 absolute bg-white min-h-[30vh] left-0 top-[-100%] w-full md:static md:min-h-fit md:w-auto'>
            <ul className='flex md:flex-row flex-col md:items-center md:gap-5 gap-6'>
              <li><Link className='hover:text-gray-500 hover:underline decoration-indigo-900 decoration-2 underline-offset-8' to='/'>Home</Link></li>
              <li><Link className='hover:text-gray-500 hover:underline decoration-indigo-900 decoration-2 underline-offset-8' to='/blogs'>Blogs</Link></li>
              <li><Link className='hover:text-gray-500 hover:underline decoration-indigo-900 decoration-2 underline-offset-8' to='/courses'>Courses</Link></li>
              <li><Link className='hover:text-gray-500 hover:underline decoration-indigo-900 decoration-2 underline-offset-8' to='/contactus'>Contact us</Link></li>
            </ul>
          </div>
          <div className='flex items-center gap-6'>
            <button className='bg-violet-600 text-white px-4 py-2 rounded-full hover:bg-violet-700 hidden md:block'>Sign Up</button>
            <ion-icon class="cursor-pointer text-3xl md:hidden" onClick={onToggleMenu} name="menu"></ion-icon>
            <span>
              <img className='rounded-[30px] w-8 h-8 md:w-12 md:h-12' src={founderImage} alt="..." />
            </span>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar;